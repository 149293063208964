import React from "react";
import { useNavigate, useLocation } from 'react-router-dom'; // import these
import PropTypes from "prop-types";
import { AuthContext } from '../../contexts/AuthContext';

import { Link } from "react-router-dom";

//import './loginbox.css';
import './login.css';

import AnimatedBackground from '../../components/animbg';





export default class Login extends React.Component {
  static propTypes = {
    seed: PropTypes.string,
  };

  static contextType = AuthContext;


  constructor(props){
    super(props);

    this.seed = props.seed;

    this.state = {
      username: '',
      password: '',
      showWarning: false, // Here
      redirectToReferrer: false, // Add this state
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleUsernameChange = event => {

    const uname = event.target.value;


    this.setState({ username: event.target.value });
  }


  handlePasswordChange = event => {
    // if password is invalid flag it


    this.setState({ password: event.target.value });
  }

  handleSubmit = async event => {
    event.preventDefault();

    // construct our parmas
    const { username, password } = this.state;

    // TODO: if not valid username, return

    // TODO: if not valid password, return

    // TODO: catch and warned on failed login

    try {
      await this.context.authenticate(username, password);
      this.setState({ showWarning: false });
      const { state = {} } = this.props.location;
      this.props.navigate(state?.from || '/c', { replace: true }); // Redirect to /dashboard if state.from is not defined

    } catch(e) {
      console.log(e)
      this.setState({ showWarning: true });

    }

  }



  // Add flex box centered login box
  render() {

    const { state = {} } = this.props.location; // provide a default value to prevent destructuring from `undefined`
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      this.props.navigate(state?.from || '/', { replace: true });
      return null;
    }




    return (
      <div className='login page'>
        <section className="vh-100 gradient-custom">
          <AnimatedBackground seed={this.seed}/>

          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-16 col-md-10 col-lg-8 col-xl-6">
                <div className="card bg-dark text-white" style={{"borderRadius":"1rem"}}>
                  <div className="card-body p-5 text-center">

                    <div className="mb-md-5 mt-md-4 pb-5">
                      <form onSubmit={this.handleSubmit}>


                        <h2 className="fw-bold mb-2 text-uppercase">Login</h2>
                        <p className="text-white-50 mb-5">Please enter your login and password!</p>

                        <div className="form-floating mb-4 nsf has-validation">
                          <input type="text" className="form-control" id="floatingInput" placeholder="example" value={this.state.email} onChange={this.handleUsernameChange} />
                          <label htmlFor="floatingInput">Username</label>
                           <div id="validationServerUsernameFeedback" className="invalid-feedback">
                              Usernames can only be lowercase alphanumeric
                          </div>
                        </div>

                        <div className="form-floating mb-4 nsf has-validation">
                          <input type="password" id="floatingPassword" className="form-control" placeholder="Password" value={this.state.password} onChange={this.handlePasswordChange} />
                          <label htmlFor="floatingPassword">Password</label>
                           <div id="validationServerPasswordFeedback" className="invalid-feedback">
                            Password must include lower, upper, numeric and special charracters or be over 25 charracters long
                          </div>
                        </div>


                        <p className="small my-5 pb-lg-2 text-white-50">Forgot <a className='text-white-50' href="#!">username</a> or <a className='text-white-50' href="#!">password</a>?</p>

                        {this.state.showWarning &&
                          <div className="alert alert-danger my-5" role="alert">
                            Invalid username or password
                          </div>
                        }

                        <button className="btn btn-outline-light btn-lg px-5" type="submit">Login</button>

                      </form>

                    </div>

                    <div>
                      <p className="mb-0">Don't have an account? <a href="#!" className="text-white-50 fw-bold">Sign Up</a>
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>


      </div>
    );
  }
}


