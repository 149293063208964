import { Navigate, useLocation } from 'react-router-dom'; // Add useLocation
import { useContext } from 'react';
import { AuthContext } from './contexts/AuthContext';

const PrivateRoute = ({ children }) => {
  const { authState } = useContext(AuthContext);
  const {username, fullName, profileUrl} = authState;
  const location = useLocation(); // Get the current location


  return username
    ? children
    : <Navigate to="/login" replace state={{ from: location }} />; // Pass the current location in state
}

export default PrivateRoute;

