import React, { useState } from 'react';
import axios from 'axios';

import './toggle.css';

function ToggleSwitch({ option, uri }) {
  const [state, setState] = useState('off');

  const handleToggle = () => {
    // Set state to waiting while waiting for server response
    setState('waiting');

    axios.post(uri, { option: !option })
      .then(() => {
        // On success, update state to reflect the new status
        setState(option ? 'off' : 'on');
      })
      .catch(() => {
        // On failure, revert state to previous status
        setState(option ? 'on' : 'off');
      });
  };

  return (
    <div>
      <input type="checkbox" id="customToggle" className="custom-toggle" onClick={handleToggle} checked={state === 'on' ? true : false}/>
      <label htmlFor="customToggle" className="custom-toggle-label"/>
    </div>
  );
}

export default ToggleSwitch;

