
import { select } from 'd3-selection';
import { hexbin } from 'd3-hexbin';
import { scaleLinear } from 'd3-scale';

import seedrandom  from 'seedrandom';

export default function hexgrid(node, seed){

  let pw = node.clientWidth,
      ph = node.clientHeight;

  let hb = hexbin()
      .size([pw, ph])
      .radius(20);

  select(node).select('svg').remove()

  let gradpage = select(node)
          .insert('svg')  
      .attr('width',pw)
      .attr('height',ph)
      .attr('class','fp-svg')

  let defs = gradpage.append("svg:defs");

  let mask = defs.append('svg:mask')
      .attr("id", "mask")

  mask.append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", pw)
      .attr("height", ph)
      .style('fill','#fff')

  //let hcs = hb.centers();
  //let mi = max(hcs.map(function(d){ return +d[0]}))
  //let cx = mi,
  //    cy = 0;
  //
  //let ff = [
  //     [cx-1,cy-1]
  //    ,[cx-1,cy]
  //    ,[cx,cy-1]
  //    ,[cx+1,cy]
  //    ,[cx-1,cy+1]
  //    ,[cx,cy+1]
  //].map(function(d){ return d.join('-')})

  let mc = scaleLinear()
      .range(['#fff','#ddd'])
      .domain([0,1])

  let rng = seedrandom(seed);

  mask.selectAll('.maskbin')
      .data(hb.centers())
    .enter().append('path')
      .attr("d", function(d) { return "M" + d[0] + "," + d[1] + hb.hexagon(); })
      .style('fill',function(d,i){

          //if (includes(lu, ff)){
          //  console.log('hello')
          //  return '#888';
          //}

          //return mc(Math.random())
          return mc(rng())
      })
      .style('stroke-width','2px')
      .style('stroke','#ddd')

  let gradient = defs.append("svg:linearGradient")
      .attr("id", "gradient")
      .attr("x1", "100%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")
      .attr("spreadMethod", "pad");

  gradient.append("svg:stop")
      .attr("offset", 0)
      //.attr("stop-color", "rgb(217, 217, 217)")
      .attr("stop-color", "rgb(31,31,31)")
      .attr("stop-opacity", 1)
      //.attr('stop-color',"#fefdfe")


  gradient.append("svg:stop")
      .attr("offset", 0.25)
      .attr("stop-color", "rgb(1,132,167)")
      //.attr("stop-color", "rgb(43, 152, 148)")
      .attr("stop-opacity", 1)
      //.attr('stop-color',"#1d2b64")

  gradient.append("svg:stop")
      .attr("offset", 1)
      .attr("stop-color", "rgb(202,94,237)")
      //.attr("stop-color", "rgb(26, 109, 238)")
      .attr("stop-opacity", 1)
      //.attr('stop-color',"#0e153a")


  gradpage.append('g')
      .append('rect')
      .attr('width',pw)
      .attr('height',ph)
      .style("fill", "url(#gradient)")
      .attr("mask", "url(#mask)");

}

