import React from "react";

import './anim.css';
import hexgrid from './hexgrid';

export default class AnimatedBackground extends React.Component {

  constructor(props){
    super(props);

    this.bgref = React.createRef();
    this.seed = props.seed;
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    // let's add our gradient animation
    hexgrid(this.bgref.current, this.seed);
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    hexgrid(this.bgref.current, this.seed);
  }
  // Todo: create animated background
  // We'll steal some old work here for now
  render() {
    return (
      <div ref={this.bgref} className="anim-background"></div>
    )
  }
}

