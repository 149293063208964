import React from "react";
import { Link } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';
import Dropdown from 'react-bootstrap/Dropdown'
import './header.css';


function toggleDarkMode(){
  document.documentElement.toggleAttribute('data-dark-mode');
  localStorage.setItem('theme', document.documentElement.hasAttribute('data-dark-mode') ? 'dark' : 'light');

  // iterate over iframes and tag them 
  const iframes = document.getElementsByTagName('iframe');
  for (const iframe of iframes) {
    let cd = iframe.contentDocument;
    cd.documentElement.toggleAttribute('data-dark-mode');
  }
}


function NovemDropDown({context}) {
  const {authState, isAuthenticated, logout} = context;
  const {username, fullName} = authState;

  const handleLogout = async () => {
    await logout();
  }

  let letter = '?'

  if(fullName || username){
    letter = !!fullName ? fullName[0] : username[0]
  }


  return (
    isAuthenticated ? (
      <Dropdown className="usermenu">
        <Dropdown.Toggle variant="success" id="dropdown-basic" className="round-dropdown">
          {letter}
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Dropdown.Header>@{username}</Dropdown.Header>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/profile/">Profile</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/c/">Minerva</Dropdown.Item>
          <Dropdown.Item href="https://novem.no/docs/getting_started/introduction/">Documentation</Dropdown.Item>
          <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
       ) : (
      <Link id="signin" role="button" className="btn btn-link order-md-1 btn-login" to="/login" type="button" aria-label="Sign in to novem"><div>Sign in</div></Link>
    )
  );
}




export default class Header extends React.Component {
  static contextType = AuthContext;

  constructore (props) {
    //const { authState } = useContext(AuthContext);
    //const {username, fullName, profileUrl} = authState;

  }


  handleLogout = async () => {
    // Perform logout action here
    await this.context.logout();
    //navigate to login perhaps

  }


  render() {
    return (
      <header className="navbar navbar-expand-md navbar-light doks-navbar cd-morph-dropdown">
       <nav className="lg-mx-5 container-xxl flex-wrap flex-md-nowrap main-nav" aria-label="Main navigation">
          <div className="navbuttons me-auto">
            <Link className="navbar-brand p-0 me-auto" to="/" aria-label="novem">novem</Link>
          </div>
          <button className="btn btn-menu d-block d-md-none order-5" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDoks" aria-controls="offcanvasDoks" aria-label="Open main menu">
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu">
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
             </svg>
          </button>
          <div className="offcanvas offcanvas-start border-0 py-md-1" tabIndex="2" id="offcanvasDoks" data-bs-backdrop="true" aria-labelledby="offcanvasDoksLabel">
             <div className="header-bar d-md-none"></div>
             <div className="offcanvas-header d-md-none">
                <h2 className="h5 offcanvas-title ps-2" id="offcanvasDoksLabel"><a className="text-dark" href="/">novem</a></h2>
                <button type="button" className="btn-close text-reset me-2" data-bs-dismiss="offcanvas" aria-label="Close main menu"></button>
             </div>


          </div>
          <div className="navbuttons order-md-2 d-flex justify-content-end">

             <NovemDropDown context={this.context}/>

             <button id="mode" onClick={toggleDarkMode} className="btn btn-link order-md-2" type="button" aria-label="Toggle user interface mode">
                <span className="toggle-dark">
                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-moon">
                      <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                   </svg>
                </span>
                <span className="toggle-light">
                   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentcolor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-sun">
                      <circle cx="12" cy="12" r="5"></circle>
                      <line x1="12" y1="1" x2="12" y2="3"></line>
                      <line x1="12" y1="21" x2="12" y2="23"></line>
                      <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                      <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                      <line x1="1" y1="12" x2="3" y2="12"></line>
                      <line x1="21" y1="12" x2="23" y2="12"></line>
                      <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                      <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                   </svg>
                </span>
             </button>
          </div>
       </nav>
    </header>
    )
  }
}

