import React, { useContext, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";

import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { ChatProvider } from './contexts/ChatContext';
import PrivateRoute from './PrivateRoute.jsx';

//import LoginPage from './LoginPage';
import LoginPage from './pages/login';
import DashboardPage from './DashboardPage';
import ChatPage from './pages/chat';
import ChatHero from './pages/hero';
import Profile from './pages/profile';


const seed = 'asd';

function setDarkMode(){
  document.documentElement.setAttribute('data-dark-mode', '');

  // iterate over iframes and tag them 
  const iframes = document.getElementsByTagName('iframe');
  for (const iframe of iframes) {
    let cd = iframe.contentDocument;
    cd.documentElement.setAttribute('data-dark-mode', '');
  }
}

function setLightMode(){
  document.documentElement.removeAttribute('data-dark-mode');

  // iterate over iframes and tag them 
  const iframes = document.getElementsByTagName('iframe');
  for (const iframe of iframes) {
    let cd = iframe.contentDocument;
    cd.documentElement.removeAttribute('data-dark-mode');
  }
}

const darkMode  = () => {
    const el = event => {
      if (event.matches) {
        localStorage.setItem('theme', 'dark');
        setDarkMode()
      } else {
        localStorage.setItem('theme', 'light');
        setLightMode()
      }
    }

    const media = window.matchMedia('(prefers-color-scheme: dark)');

    if ('addEventListener' in media) {
      media.addEventListener('change', el)
    } else if ('addListener' in media) {
      media.addListener(el)
    }

    //mode.addEventListener('click', () => {
    //  toggleDarkMode()
    //});

    if (localStorage.getItem('theme') === 'dark') {
      setDarkMode();
    } else {
      setLightMode();
    }

}


function LoginWrapper(props) {
  const location = useLocation();
  const navigate = useNavigate();

  return <LoginPage {...props} location={location} navigate={navigate} />;
}

      //<Route path="/login" element={<LoginPage seed={seed} location={{ from: '/login' }} />} />

function RedirectToChat() {
  let navigate = useNavigate();

  // useEffect to run once, when the component mounts.
  React.useEffect(() => {
    navigate('/c', { replace: true });
  }, [navigate]);

  return null;  // this component doesn't render anything
}

const App = () => {
  const { loading } = useContext(AuthContext);


  /* load our dark mode etc */
  useEffect(() => {
      darkMode() // setup darkmode logic

      // set the chatlog to zero on app creation
  }, []); // Empty dependency array ensures this runs once on mount

  if (loading) {
    return <h2>Loading...</h2>; // or your custom loading component
  }

  return (
    <Routes>
      <Route path="/login" element={<LoginWrapper seed={seed}/>} />

      <Route path="/profile" element={<PrivateRoute><Profile/></PrivateRoute>} />
      <Route path="/c" element={<PrivateRoute><ChatPage /></PrivateRoute>} />

      <Route path="/" element={<RedirectToChat />} />
    </Routes>
  );
};

/*
<Route path="/" element={<ChatHero/>} />
*/
const wrap = () => (
  <AuthProvider>
    <ChatProvider>
      <App />
    </ChatProvider>
  </AuthProvider>
);

export default wrap;
