import { visit } from 'unist-util-visit';
import { load as parse } from 'js-yaml';
import { u } from 'unist-builder';

function escapeHtml(unsafe)
{
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }

export function examplePlugin() {
  return (tree) => {

    let ref = []


    visit(tree, 'paragraph', (node, index, parent) => {
      const textNode = node.children[0];
      const smatch = textNode?.value?.match(/{{ example "([^"]+)" "([^"]+)" }}/);

      if (smatch) {
        let str = textNode?.value
        let regex = /"([^"]*)" "([^"]*)"/g;
        let match;
        let examples = [];

        while ((match = regex.exec(str)) != null) {
          examples.push({'key': escapeHtml(match[1]), 'val': escapeHtml(match[2])});
        }

        let vs = JSON.stringify(examples)

        const exampleNode = {
          type: 'example',
          example: vs,
          data: {
            hName: 'example',
          },
          children: [{ type: 'text', value: vs}]
        };

        parent.children.splice(index, 1, exampleNode);
      }
    });
  };

}

