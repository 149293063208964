import React from "react";




export default class Footer extends React.Component {



  render() {
    return (
      <div className="shadebg pb-4 footer">
        <div className="wrap container">
          <div className="wrap container shadebg bgblock"></div>
          <footer className="row justify-center mb-2">
            <div className="col-lg-7 col-sm-4">
              <div className="h5">&nbsp;</div>
              <a
                href="https://novem.no/"
                className="d-flex align-items-center mb-3 link-dark text-decoration-none novemlogo"
                alt="novem logo"
                aria-label="home page"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 102 37"
                  width="80"
                  height="40"
                >
                  <g transform="translate(18.5, 18.5)">
                    <mask id="cmask">
                      <circle r="17" cy="0" cx="0" style={{fill:"#fff"}}></circle>
                      <circle
                        className="circles2"
                        r="20"
                        cy="0"
                        cx="25"
                        style={{fill:"#000"}}
                      ></circle>
                    </mask>
                    <circle
                      r="17"
                      cy="0"
                      cx="0"
                      style={{fill:"currentcolor","mask":"url(#cmask)"}}
                    ></circle>
                    <circle r="17" cy="0" cx="25" style={{fill:"currentcolor"}}></circle>
                    <circle r="17" cy="0" cx="65" style={{fill:"currentcolor"}}></circle>
                  </g></svg
                ><br
              /></a>
              <p>© novem</p>
              <div className="footerc"><p></p></div>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="mb-2 h4">Products</div>
              <ul className="nav flex-column">
                <li className="nav-item mb-1">
                  <a href="https://novem.no/products/charts/" className="nav-link p-0">Charts</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/products/documents/" className="nav-link p-0">Documents</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/products/emails/" className="nav-link p-0">E-Mails</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/products/dashboards/" className="nav-link p-0">Dashboards</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/products/reporting/" className="nav-link p-0">Reporting</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="mb-2 h4">Documentation</div>
              <ul className="nav flex-column">
                <li className="nav-item mb-1">
                  <a href="https://novem.no/docs/api/overview/" className="nav-link p-0">API</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/docs/libraries/overview/" className="nav-link p-0"
                    >Libraries</a
                  >
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/docs/plot/charts/overview" className="nav-link p-0">Charts</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/about/pricing/" className="nav-link p-0">Pricing</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/about/pricing/" className="nav-link p-0">Enterprise</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/contact/" className="nav-link p-0">Contact</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-sm-4">
              <div className="mb-2 h4">Company</div>
              <ul className="nav flex-column">
                <li className="nav-item mb-1">
                  <a href="https://novem.no/about/" className="nav-link p-0">About</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/legal/privacy/" className="nav-link p-0">Privacy</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/blog/" className="nav-link p-0">Articles</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/people/" className="nav-link p-0">People</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/media/" className="nav-link p-0">Media</a>
                </li>
                <li className="nav-item mb-1">
                  <a href="https://novem.no/about/#frequently-asked-questions" className="nav-link p-0"
                    >FAQs</a
                  >
                </li>
              </ul>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

