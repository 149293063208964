import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import {api_root, web_root} from '../conf.js';

export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {

  const [authState, setAuthState] = useState({ username: null, fullName: null, profileUrl: null });
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);

  const getUserInfo = async () => {

    // check if we alreay have user info
    const {username, fullName, profileUrl} = authState;

    if(username)return;
    


    try {
      const profileResponse = await axios.get(`${api_root}/v1/admin/profile/overview`, { 
        withCredentials: true,
        headers: {
          'Accept': 'application/json'
        }
      });

      const pfi = profileResponse.data

      setAuthState({
        username: pfi.user_info.username,
        fullName: pfi.user_info.name,
        profileUrl: pfi.user_info.avatar,
        bio: pfi.user_info.bio,
      });

      setAuthenticated(true);

    } catch (error) {
      //console.log(error)
      //console.log('Not logged in');
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const authenticate = async (username, password) => {
    const authResponse = await axios.post(`${api_root}/v1/auth`, 
      { username, password },{
        withCredentials: true,
    });
    if (authResponse.status === 200) {
      await getUserInfo();
    }
  };

  const logout = async () => {
    const authResponse = await axios.post(`${api_root}/v1/logout`, {}, { 
      withCredentials: true
    });
    if (authResponse.status === 200) {
      // reset auth state
      setAuthState({ username: null, fullName: null, profileUrl: null })    
      setAuthenticated(false);
    }
  };

  return (
    <AuthContext.Provider value={{ authState, authenticate, logout, loading, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

