import React, { useState, useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../../contexts/AuthContext';


import Header from '../../components/header';
import Footer from '../../components/footer';

import ToggleSwitch from './toggle';

import './profilehead.css';

// create head

const ProfileHead = React.memo((props) => {

  const {username, fullName, profileUrl,bio} = props.userInfo;

  return ( 
    <section className="container d-flex justify-content-center my-5">
        <div className="row col-lg-10 ">

          <div className="col-lg-4">
            <img src={profileUrl} className="border rounded-circle overflow-hidden w-100 h-100 profile-img" alt="..."/>
          </div>

          <div className="col-lg-12 d-flex flex-column justify-content-center align-items-cente g-1">
            <div>

              <div className=" display-5">
                {fullName}
              </div>
              <div className=" fs-3">
                @{username}
              </div>
              <div className="fs-4 mt-1">
                {bio}
              </div>

            </div>
          </div>

          <div className="col-lg-12 d-flex flex-column justify-content-center align-items-cente mt-5">
            <div>
            </div>
          </div>

      </div>
    </section>
  
  )

})


// <ToggleSwitch option='on' uri='https://dev.api.novem.no/v1/u' />




export default class Profile extends React.Component {
  // Let's add our jumbo hero
  static contextType = AuthContext;


  render(){

    const {authState, isAuthenticated, logout} = this.context;
    const {username, fullName, profileUrl} = authState;

    return (
      <div className='h-full'> 
        <div className="header-bar" />
        <Header />

        <ProfileHead userInfo={authState}/>




        <Footer />
      </div>
    );
  };
};

