import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { useChat } from '../../contexts/ChatContext';
import { AuthContext } from '../../contexts/AuthContext';
import LoginPage from '../../LoginPage';
import {api_root, web_root, ws_root} from '../../conf.js';

import { useSearchParams } from 'react-router-dom';




import { select } from 'd3-selection';
import { range } from 'd3-array';
import { transition } from 'd3-transition';

import hljs from 'highlight.js';

import Header from '../../components/header';

import ReactMarkdown from 'react-markdown';

import gfm from 'remark-gfm';
import { visit } from 'unist-util-visit';
import { load as parse } from 'js-yaml';

import { partial, curry, findIndex, always, prop, propEq, adjust, filter, append, compose } from 'ramda';
import { u } from 'unist-builder';

import { examplePlugin } from './examplePlugin';



function escapeHtml(unsafe)
{
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
 }

function embedPlugin() {
  return (tree) => {
    visit(tree, 'paragraph', (node, index, parent) => {
      const textNode = node.children[0];
      const match = textNode?.value?.match(/{{ embed "([^"]+)" }}/);

      if (match) {
        const shortcode = escapeHtml(match[1]);

        const shortcodeNode = {
          type: 'shortcode',
          shortcode: shortcode,
          data: {
            hName: 'shortcode',
          },
          children: [{ type: 'text', value: shortcode }]
        };

        parent.children.splice(index, 1, shortcodeNode);
      }
    });
  };
}


function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() *
               charactersLength));
         }
   return result;
}


const logo = (ref, status) => {
    let op = 4 
    let margin  = {top : op, bottom: op, left: op, right: op},
      width   = 80,
      height  = 80,
      w       = width - margin.left - margin.right,
      h       = height - margin.top - margin.bottom


    let narray = range(9)

    let cx = w/2
    let cy = h/2

    // draw 9 little cirlces
    //let hyp = w/2 - margin.left;
    let hyp = 12
    let r = 24

    const del = 60
    const dur = 400

    let t = select(ref.current)
    t.attr('class',status)
      .classed('chat-server',true)

    //if status is completed we will make sure we are not animating
    if(status === 'complete' || status === 'error'){
      t.selectAll('.dots').interrupt().transition("end")
        .delay(function(d,i){ return i*del})
        .duration(dur).attr('stroke-width',1)
    }


    if(!ref.current || t.classed('running')){return}

    t.classed('running',true)

    t.selectAll('.bx')
          .data([1])
    .enter().append('g')
        .classed('bx',true)


    let svg = t.select('.bx')
          .attr('transform','translate('+margin.left+','+margin.top+')');

    let gr = function(d,i){ return (2 * Math.PI) * (i/narray.length) }

    let y = compose(function(d){ return cy + hyp*d}, compose(Math.sin, gr))
    let x = compose(function(d){ return cx + hyp*d}, compose(Math.cos, gr))
  

    svg.selectAll('.dots')
        .data(narray, function(d,i){ return i})
      .enter().append('circle')
        .attr('r',0)
        .attr('class','lit')
        .classed('dots',true)
        .attr('cx',x)
        .attr('cy',y)
        .attr('stroke-width', 1)
        .attr('data-index', function(d, i) { return i; }) // Store index
    .transition("grow")
        .delay(function(d,i){ return i*del})
        .duration(dur)
        .attr('r',r)
    .transition('prep').delay(100)
    //.on("end", growAndShrink);



    const loop = () => {
      if((t.classed('complete') || t.classed('error'))){
        return;
      }

      let sdur = 900*2;

      svg.selectAll('.dots')
        .transition("gsg")
        .delay(function(d,i){ return i*(100)})
        .duration(sdur/2)
        .attr('stroke-width', 3)
        .transition("gss")
        .duration(sdur/2)
        .attr('stroke-width', 1)

      if(!(t.classed('complete') || t.classed('error'))){
        setTimeout(loop, (900*2) + 500)
      }
    }

    if(status !== 'complete' && status !== 'error'){
      setTimeout(loop, 1000)
    }
}


const ChatIcon = React.memo((props) => {
  // our chat icon!!
  const from = props.from;
  const profileUrl = props.profileUrl
  const status = props.status;

  const actionList = props.actionList;

  const ref = useRef();

  // on our first entry we will animate

  // while not complete we will pulsate

  // when complete we will transition to standard look

  // if failed we will enter fail state
  useEffect(() => {
    logo(ref, status)
  }, [status]);



  return ( 
    <div className='col-2 p-0 chat-img d-flex justify-content-end'>
      <div>
        {from === 'server' ?
          <div className='chat-avatar col-16'>
          <svg ref={ref} width="80" height="80" className="chat-server">
          </svg>
          </div> :
          <div className='chat-avatar'><img src={profileUrl} alt="Profile"></img></div>
        }
        <div className='chat-action-list'>
          {actionList.map((d, index) => {
            return (
              <div key={index} className={`ca-${d.id}`} />
            )}
          )}
        </div>
      </div>
    </div>
  
  )

})




const ChatItem = React.memo((props) => {
  const from = props.from;
  const message = props.message;
  const profileUrl = props.profileUrl
  const status = props.status;
  const actionList = props.actionList;

  const css = props.css;

  const renderRef = React.createRef();

  //const gsm = props.sm;

  const updatePlots = () => {
    // let's select our document for new embeds and register
    const rt = select(renderRef.current)
    let embeds = rt.selectAll('[novem-vis]:not(.processed)')
      
    // We should only animate if we are in the most recent window
    embeds.each(function(d) {
        let t = select(this)
        t.classed('processed',true)
        let id = t.attr('novem-vis')
        let rid = makeid(10)

        let fig = t.select('figure')
        fig.attr('id',rid)

        let btn = t.select('a')
        btn.attr('href',`${web_root}/p/${id}`);

        // await api lookup for value
        axios.get(`${api_root}/v1/i/${id}`, { 
          withCredentials: true,
        }).then(response=> {

          const info = response.data;
          if(info?.about?.vis_type === 'plot') {
            window.ns.register("p", id, rid);
          } else if(info?.about?.vis_type === 'mail'){
            window.ns.register("m", id, rid);
          } else {
            window.ns.register("p", id, rid);
          }
        })
      

        // give me a post render hook NS
        setTimeout(d=>renderRef.current.scrollIntoView({ behavior: 'smooth' }), 250)
    })

  }

  const hl = () => {
    //highlight our code

    const codeBlocks = renderRef.current.querySelectorAll('pre code');
    codeBlocks.forEach(block => hljs.highlightElement(block));

  }

  useEffect(() => {
    // update plot
    updatePlots()

    renderRef.current.scrollIntoView({ behavior: 'smooth' });

    // let's add code highlight
    hl();

  }, [message])




  const ShortcodeComponent = React.memo(({node}) => {
    const shortcode = node.children[0].value;
      return (
        <div className="col-lg-16 m-0 novem--vis">
          <div novem-vis={shortcode}>
            <figure className="mt-0 mb-0" style={{minHeight: '300px'}}></figure>
            <div className="backtn">
              <a target="_blank" rel="noopener" className="obtn" href={`${web_root}/p/${shortcode}`} role="button">
                more details →
              </a>
            </div>
          </div>
        </div>
      )
   });

  const ExampleComponent = React.memo(({node}) => {
    const values = JSON.parse(node.children[0].value);

    const handleClick = (val) => {
      css(val);
    };

    return (
      <div className="col-16 m-0 d-flex justify-content-center flex-wrap tipboxes">
        {values.map((val, index) => (
          <div 
          className="card col-7 col-lg-3 m-3 m-0 p-4 text-center d-flex justify-content-center align-items-center tipbox" 
            key={index}
            onClick={() => handleClick(val.val)}>
          {val.key}
          </div>
        ))}
      </div>
    )
   });

  return (
          <div ref={renderRef} className={`chat-item chat-${from} ${status}`}>
            <div className='wrap container-xxl mt-0 mb-0 pt-5 pb-5'>
              <div className="content">

                <div className='row justify-content-center'>
                  <ChatIcon from={from} actionList={actionList} message={message} status={status} profileUrl={profileUrl}/>
                  <div className='col-lg-10 col-md-12 col-14 py-2 pt-4 px-4 d-flex align-items-center'>
                    <div className='col-16'>
                      <ReactMarkdown 
                        remarkPlugins={[gfm, embedPlugin, examplePlugin]}
                        components={{
                          shortcode: ShortcodeComponent,
                          example: ExampleComponent,
                          a: ({node, ...props}) => <a {...props} target="_blank" rel="noopener" />
                        }}>{status === "chatting"?"":message}</ReactMarkdown>
                    </div>
                  </div>
                  <div className='col-1 py-2'>
                  </div>
                </div>

              </div>
            </div>
          </div>
  )


})









const ChatPage = () => {
  const { chatLog, setChatLog } = useChat([]);
  const [inputValue, setInputValue] = useState('');

  const [chatState, setChatState] = useState('');

  const [comState, setComState] = useState('');

  const { authState } = useContext(AuthContext);
  const {username, fullName, profileUrl} = authState;

  const ws = useRef(null);
  
  const textareaRef = React.createRef();


  const processActions = (actions) => {
    const result = [];

    actions.forEach(actionObj => {
      let action = actionObj.id;
      let data = prop('d',actionObj) ;
      let pN;
      let event;

      if (action.includes('~')) {
        const [_, pNEvent] = action.split('~');
        [pN, event] = pNEvent.split('-');
      } else {
        [pN, event] = action.split('-');
      }

      // Handle start and stop events
      if (event === 'start') {
        // Always add a new 'init' when there's a 'start'
        result.push({ id: `${pN}-init`, d:data});
      } else if (event === 'stop') {
        // Find the last 'init' for the current pN and change it to 'complete'
        for (let i = result.length - 1; i >= 0; i--) {
          if (result[i].id === `${pN}-init`) {
            result[i] = { id: `${pN}-complete`, d:data };
            break;
          }
        }
      } else if (event === 'init' || event === 'complete') {
        // Handle already processed 'init' and 'complete' events
        result.push({ id: `${pN}-${event}`, d:data });
      }
    });

    return result;
  };





  const modLog = (data, prevChatLog) => {
    const mergeAlLists = (existingAl, newAl) => {
      // Insert your custom merge logic here
      // For now, I'm just concatenating the two lists
      //console.log('---')
      let mgd = [...existingAl, ...newAl]
      //console.log(`input: `,mgd);

      // only keep chatting
      mgd = filter(d=>{
        let [s, m] = d.id.split('~');
        return (
          s === 'chatting' 
          || s === 'p1-init' 
          || s === 'p1-complete' 
          || s === 'p2-init' 
          || s === 'p2-complete' 
        )&& m!== "init" 
      }, mgd)

      //console.log('filter', mgd)

      let comp = processActions(mgd)

      //console.log('comp', comp)


      return comp;
    };

    const uoa = curry((list, id, objToAppend) => {
      const index = findIndex(propEq('id', id), list);

      if (index !== -1) {
        const item = list[index];
        const mergedItem = {
          ...item,
          ...objToAppend,
          al: mergeAlLists(item.al || [], objToAppend.al || []), // use custom merge function here
        };
        return adjust(index, always(mergedItem), list);
      } else {
        return append(objToAppend, list);
      }
    });

    return uoa(prevChatLog, data.id, {
      id: data.id,
      f: data.src,
      m: data.m,
      al: data.src !== 'server' ? [] : [{id:`${data.s}~${data.m}`, d:data.d}],
      s: data.s,
    });
  };


  // Assume the connection is closed if no messages have been received for 10 seconds
  let lastMessageTimestamp = Date.now();

  const [searchParams] = useSearchParams();
  useEffect(() => {

    // Getting the 'model' and 'tag' parameters
    const model_val = searchParams.get('model');
    const tag_val = searchParams.get('tag');

    let model = ''
    let tag = ''
    let qpm = ''


    if (model_val && tag_val) {
      qpm = `?model=${model_val}&tag=${tag_val}`;
    } else if (model_val) {
      qpm = `?model=${model_val}`;
    } else if (tag_val) {
      qpm = `?tag=${tag_val}`;
    }



    ws.current = new WebSocket(`${ws_root}/chat/${qpm}`);

    //const hb = setInterval(() => {
    //  if (ws.current.readyState === WebSocket.OPEN) {
    //    ws.current.send('heartbeat');
    //  }
    //}, 5000);


    //setInterval(() => {
    //  if (Date.now() - lastMessageTimestamp > 10000) {
    //    //console.error('WebSocket connection seems to be closed, no messages received for 10 seconds');
    //    clearInterval(hb)
    //    ws.current.close();
    //    // Handle connection loss
    //    //
    //  }
    //}, 1000);


    // reset our chatlog on entry
    setChatLog([])

    ws.current.onmessage = (message) => {
      lastMessageTimestamp = Date.now();  // Update the timestamp of the last received message

      // If we received a heartbeat message, send one back
      if (message.data === 'heartbeat' ) {
        return;
      }

      const data = JSON.parse(message.data);
      setChatState(data.s)
      setChatLog(partial(modLog, [data]))
    };


    ws.current.onclose = (event) => {
      //console.log('WebSocket closed', event.code, event.reason);
      //console.log(event)
      //console.log(ws)
    }


    // focus text area on page load
    textareaRef.current.focus();

    return () => {
      ws.current.close()
    };

  }, []);

  const checkOverflow = () => {
    const lineHeight = 24;
    textareaRef.current.style.height = 'auto'; // Temporarily reset the height
    const totalLines = Math.floor(textareaRef.current.scrollHeight / lineHeight);
    let h = totalLines * 24;
    textareaRef.current.style.height = `${h}px`; // set new height

    if(h>200){
      textareaRef.current.style.overflowY = `scroll`;
    }else{
      textareaRef.current.style.overflowY = `hidden`;
    }

  }

  const sendMessage = (val) => {
    if(val === "" || (chatState !== 'complete' && chatState !== 'error')){
      return;
    }

    const id = `mid-${Math.random().toString(36).substr(2, 10)}`;

    ws.current.send(JSON.stringify({ message: val, id: id}));

    setChatLog(partial(modLog, [{
      id: id,
      src: 'user', 
      m: val,
      al: [],
      s: 'complete',
    }]))

  }

  useEffect(() => {
    sendMessage(comState);
  }, [comState]);  // Pass comState as a dependency

  const handleSubmit = (event) => {

    if(inputValue === "" || (chatState !== 'complete' && chatState !== 'error')){
      event.preventDefault();
      return;
    }

    sendMessage(inputValue)

    setInputValue('');

    event.preventDefault();
  };

  const handleKeyPress= (event) => {
    if (event.key === 'Enter'  && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
      textareaRef.current.style.height = `24px`; // set new height
    } 
  }
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    checkOverflow()
  };


  return (
  <div className='h-full'> 
    <div className="header-bar" />
      <Header />

      <div className='outer-chart'>

      <div className='chat-item minerva'>
        <div className='wrap container-xxl p-2'>
            <div className="content">
              <div className='justify-content-center'>
                <div className='col-16 py-3 text-center'>
                    minerva ~ 
                    <span style={{fontSize:'24px', paddingLeft:'5px', paddingRight:'2px'}}>&#x1D6FC;</span> 
                    1
                </div>
              </div>
            </div>
          </div>
        </div>

        {chatLog.map((d, index) => {
          return (
            <ChatItem key={d.id} actionList={d.al} from={d.f} status={d.s} css={setComState} message={d.m} profileUrl={profileUrl}/>
          )}
        )}

      </div>


      <div className='chat--input pt-2 pb-4'>
        <div className="wrap container-xxl ">
          <div className="content">
            <div className="row justify-content-center">
              <div className="col-sm-10">

              <form onSubmit={handleSubmit} className="mx-2 flex flex-row gap-3">
                <div className="relative flex flex-1 items-stretch">
                  <div className="flex flex-col p-4 flex-grow relative border bg-white chat--hold">
                    <textarea 
                      id="queryfield"
                      ref={textareaRef}
                      value={inputValue} 
                      onKeyPress={handleKeyPress} 
                      onChange={handleInputChange} 
                      tabIndex="1" rows="1" placeholder="Send a message." className="m-0  border-0 bg-transparent pl-2" >
                    </textarea>
                    <button id="submitbutton" className="btn" disabled="">
                      <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                    </button>
                  </div>
                </div>
              </form>


              </div>
            </div>
          </div>
        </div>


        <div className="px-3 pb-3 my-4 pt-2 text-center text-s text-gray-600">
          <span className='text-xs'>Minerva  may produce inaccurate information about people, places, or facts, data provided to minerva could be shared with 3rd parties</span>
        </div>
      </div>

    </div>
  );
};

export default ChatPage;
